import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { ApiEndPoint } from "../../constants/apiEndPoints/endPoints";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Select from "react-select";
import { Form, Modal, ModalBody } from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { errorAlert, successAlert } from "../Alerts/Alerts";
import {
  notificationShow,
  setContinueScreening,
  setPatientLists,
  setUnFilteredPatientLists,
} from "../../redux/Slice/EvaluatorSlice";
import moment from "moment";
import Collapse from "react-collapse";
import ErrorComponents from "../ErrorComponents/ErrorComponents";
import { ArrowSquareDown, ArrowSquareUp } from "iconsax-react";

export const SleepApneaTable = ({ evalstatus, patientType }) => {
  var continueScreening = false;
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [loaders, setLoaders] = useState(false);
  const scrollToBottom = useRef(null);
  const [modal, setModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState();

  const toggle = () => setModal(!modal);

  const {
    register: register1,
    formState: { errors: errors1 },
    handleSubmit: handleSubmit1,
    control: control1,
    reset: reset1,
  } = useForm();

  const CustomerAdminDetails = useSelector(
    (state) => state.login.loggedUserDetails
  );
  const ScreeenerDetails = useSelector(
    (state) => state.login.loggedUserDetails
  );
  const selectedPatientFromNotification = useSelector(
    (state) => state.evaluator.selectedPatientIdFormNotification
  );

  const selectedNotificationPatientType = useSelector(
    (state) => state.evaluator.selectedNotificationPatientType
  );

  const notificationsList = useSelector(
    (state) => state.evaluator.notificationsList
  );
  const evaluationStatus = useSelector(
    (state) => state?.evaluator?.selectedEvalStatus
  );
  const patientListData = useSelector((state) => state?.evaluator?.patientList);
  const unFilteredPatientListData = useSelector(
    (state) => state?.evaluator?.unFilteredPatientList
  );

  const [patientsList, setPatientsList] = useState([]);
  const [unFilteredPatientList, setUnFilteredPatientList] = useState([]);
  const [filters, setFilters] = useState([]);
  const [selectedRow, setSelectedRow] = useState();
  const [open, setOpen] = useState(false);
  const [disableFields, setDisableFields] = useState(false);
  const [yesCount, setYesCount] = useState(0);
  const [questionsData, setQuestionsData] = useState([]);
  const [answerData, setAnswerData] = useState([]);
  const [sleepApneaImages, setSleepApneaImages] = useState([]);
  const [sleepApneaComments, setSleepApneaComments] = useState("");
  const [imageEvaluationData, setImageEvaluationData] = useState();
  const [questionCount, setQuestionCount] = useState(0);
  const [totalScore, setTotalScore] = useState(0);
  const [assessmentCode, setAssessmentCode] = useState("Low Risk");
  const scrollToTop = useRef(null);

  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const [previousRes, setPreviousRes] = useState({});

  const ImageEvaluationQuestions = [
    "Large Tongue",
    "Long Soft Palate",
    "Enlarged uvula",
    "Broad Tonsillar Pillars",
    "Tonsillar Tissue",
    "Redundant Pharyngeal Tissue",
    "Linea Alba",
    "Scalloped tongue",
    "Erosion",
    "Wear On The Lower Incisors",
    "Vaulted palate",
  ];

  const evalStatus = [
    { value: "new", label: "New" },
    { value: "screened", label: "Screened" },
    { value: "optout", label: "Optout" },
    { value: "completed", label: "Completed" },
    { value: "reported", label: "Reported" },
    { value: "incomplete", label: "Incomplete" },
  ];

  const DentalPain = [
    { value: "yes", label: "Yes", dbvalue: "true" },
    { value: "no", label: "No", dbvalue: "false" },
  ];

  const patientHeader = [
    { header: "#", input: "dropdown", dbkey: "index", searchtype: "id" },
    {
      header: "Nurse Name",
      input: "dropdown",
      dbkey: "nurse_name",
      searchtype: "input",
    },
    {
      header: "Patient EMR No.",
      input: "dropdown",
      dbkey: "number",
      searchtype: "input",
    },
    {
      header: "Patient Number",
      input: "dropdown",
      dbkey: "patient_number",
      searchtype: "input",
    },
    {
      header: "First Name",
      input: "dropdown",
      dbkey: "first_name",
      searchtype: "input",
    },
    {
      header: "Last Name",
      input: "dropdown",
      dbkey: "last_name",
      searchtype: "input",
    },
    {
      header: "Phone No.",
      input: "dropdown",
      dbkey: "contact_number",
      searchtype: "input",
    },
    {
      header: "Last Modified",
      input: "dropdown",
      dbkey: "modified_date",
      searchtype: "hidden",
    },
  ];

  const AssessmentCode = [
    { value: "Low Risk", label: "Low Risk" },
    { value: "Moderate Risk", label: "Moderate Risk" },
    { value: "High Risk", label: "High Risk" },
  ];

  const GetQuestions = async () => {
    await axios.get(ApiEndPoint.sleepApneaScreeningQuestions).then((res) => {
      setQuestionsData(res.data);
    });
  };

  const GetSleepApneaData = async () => {
    axios
      .get(`${ApiEndPoint.sleepApneaScreeningPatientQNA}${selectedRow?.id}`)
      .then((res) => {
        setAnswerData(res.data.data);
      })
      .catch((err) => {
        setAnswerData([]);
      });
  };

  const GetSleepApneaImages = async () => {
    axios
      .get(`${ApiEndPoint.sleepApneaScreeningPatientImages}${selectedRow?.id}`)
      .then((res) => {
        setSleepApneaImages(res.data.data);
        setAssessmentCode(res.data.data?.treatment_recommendation_code);
        if (res.data.data.eval_status === "reported") {
          setDisableFields(true);
        }
      })
      .catch((err) => {
        setSleepApneaImages([]);
      });
  };

  const GetSleepApneaImageEvaluationResult = async () => {
    axios
      .get(`${ApiEndPoint.sleepApneaImageEvaluationResult}${selectedRow?.id}`)
      .then((res) => {
        if (res.data.status === "success") {
          setImageEvaluationData(res.data.message);
          setPreviousRes(res.data.message);
          setSleepApneaComments(res.data.message?.comments);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleYesChange = (value, namee) => {
    // Check if the field has already been selected
    if (!previousRes[namee]) {
      setPreviousRes((prevState) => ({
        ...prevState,
        [namee]: value.value,
      }));

      if (value.value === "yes") {
        setYesCount((prevCount) => prevCount + 1);
        setTotalScore((prevTotal) => prevTotal + 1);
      }
    } else if (previousRes[namee] === "no" && value.value === "yes") {
      // If the field is already selected as "no" and the new value is "yes"
      setPreviousRes((prevState) => ({
        ...prevState,
        [namee]: value.value,
      }));
      setYesCount((prevCount) => prevCount + 1);
      setTotalScore((prevTotal) => prevTotal + 1);
    } else if (
      previousRes[namee] === "yes" &&
      value.value === "no" &&
      yesCount > 0
    ) {
      // If the field is already selected as "yes" and the new value is "no"
      setPreviousRes((prevState) => ({
        ...prevState,
        [namee]: value.value,
      }));
      setYesCount((prevCount) => prevCount - 1);
      setTotalScore((prevTotal) => prevTotal - 1);
    }
  };

  useEffect(() => {
    if (yesCount) {
      yesCount >= 0 && yesCount <= 1
        ? imageEvaluationData && imageEvaluationData.eval_status === "reported"
          ? setAssessmentCode(imageEvaluationData.treatment_recommendation_code)
          : setAssessmentCode("Low Risk")
        : yesCount >= 2 && yesCount <= 6
        ? imageEvaluationData && imageEvaluationData.eval_status === "reported"
          ? setAssessmentCode(imageEvaluationData.treatment_recommendation_code)
          : setAssessmentCode("Moderate Risk")
        : yesCount >= 7 && yesCount <= 11
        ? imageEvaluationData && imageEvaluationData.eval_status === "reported"
          ? setAssessmentCode(imageEvaluationData.treatment_recommendation_code)
          : setAssessmentCode("High Risk")
        : imageEvaluationData && imageEvaluationData.eval_status === "reported"
        ? setAssessmentCode(imageEvaluationData.treatment_recommendation_code)
        : setAssessmentCode("Low Risk");
    }
  }, [yesCount]);

  const handleOnSelect = (select, value) => {
    upsert({ name: select, value: value });
  };

  const handleSearch = (select, value) => {
    upsert({ name: select, value: value });
  };

  const handleSelectStatus = (selectedStatus) => {
    if (selectedStatus) {
      upsert({ name: "eval_status", value: selectedStatus.value });
    } else {
      handleOnClear("eval_status");
    }
  };

  const handleOnClear = (select) => {
    const filteredArray = filters.filter((obj) => obj.name !== select);
    setFilters(filteredArray);
  };

  const upsert = (item) => {
    const previousfilters = filters.find((_item) => _item.name === item.name);
    if (previousfilters) {
      const updatedArray = filters.map((obj) => {
        if (obj.name === item.name) {
          return { ...obj, value: item.value };
        }
        return obj;
      });
      setFilters(updatedArray);
    } else {
      setFilters([...filters, item]);
    }
  };

  const handleRowClick = (row) => {
    setImageEvaluationData();
    if (evalstatus != "new") {
      if (selectedRow && selectedRow.id === row.id) {
        setSelectedRow(null); // Deselect row if clicked again
      } else {
        setSelectedRow(row);

        setTimeout(() => {
          const navbarHeight = 90; 
          const offset = scrollToTop.current
            ? scrollToTop.current.getBoundingClientRect().top -
              navbarHeight +
              window.scrollY
            : 0;
          if (scrollToTop.current) {
            window.scrollTo({
              top: offset,
              behavior: "smooth",
            });
          }
        }, 1000);

      }
    }
  };

  const GetPatientList = async () => {
    setImageEvaluationData();
    setAssessmentCode();

    setLoaders(true);
    const payload = {
      clinic: CustomerAdminDetails?.branch?.customer?.id,
      branch: CustomerAdminDetails?.branch?.id,
      evalstatus: evaluationStatus?.value,
      patient_type: patientType,
    };
    const evaluationURL =
      patientType === "selfscreening"
        ? ApiEndPoint.selfScreeningPatientEvaluation
        : ApiEndPoint.sleepApneaPatientEvaluation;
    await axios.post(evaluationURL, payload).then(async (response) => {
      const nurseWithName = await response?.data?.patientList?.map((item) => ({
        ...item,
        nurse_name: `${item?.nurse?.fname} ${item?.nurse?.lname}`,
        modified_date: item?.last_modified_date
          ? formatDate(item?.last_modified_date)
          : null,
        contact_number: item?.contact_number
          ? item?.contact_number.replace(/\D/g, "")
          : null,
      }));
      dispatch(setPatientLists(nurseWithName));
      dispatch(setUnFilteredPatientLists(nurseWithName));
      setLoaders(false);
      setPatientsList(nurseWithName);
      setUnFilteredPatientList(nurseWithName);
      setImageEvaluationData();
      setSleepApneaComments("");
      setYesCount(0);
      setAnswerData([]);
      setQuestionCount(0);
      setTotalScore(0);
      setPreviousRes({});
      if (continueScreening) {
        if (nurseWithName[0]) {
          setSelectedRow(nurseWithName[0]);
        } else {
          continueScreening = false;
        }
      } else {
        setSelectedRow(null);
      }
    });
  };

  const ShowPatientFromNotification = async () => {
    await GetPatientList();
  };

  const onSubmitSleepApnea = (data) => {
    setLoader(true);

    for (const key in data) {
      data[key] = data[key]?.value;
    }
    data.patient_id = selectedRow.id;
    data.eval_status = "completed";
    data.sleep_apnea_comments = sleepApneaComments ? sleepApneaComments : "";
    data.treatment_recommendation_code = assessmentCode
      ? assessmentCode
      : "Low Risk";

    axios
      .post(ApiEndPoint.sleepApneaScreeningEvaluationData, data)
      .then((res) => {
        if (res.data.status === "success") {
          successAlert(res.data.message);
          reset1();
          setLoader(false);
          setSelectedRow(null);
          GetPatientList();
          setImageEvaluationData();
          setSleepApneaComments("");
          setYesCount(0);
          setAnswerData([]);
          setQuestionCount(0);
          setTotalScore(0);
          setAssessmentCode();
          setPreviousRes({});
        } else {
          warningAlert("Patient Not Evaluated");
          setLoader(false);
          setSelectedRow(null);
        }
      })
      .catch((err) => {
        errorAlert(err.message);
        setLoader(false);
      });
  };

  const handleImageZoom = (imageURL) => {
    toggle();
    setSelectedImage(imageURL);
    setOpen(!open);
  };

  useEffect(() => {
    setQuestionCount(0);
    setImageEvaluationData();
    reset1();
    setTotalScore(0);
    setYesCount(0);
    setAssessmentCode();
    setSleepApneaComments("");
    setPreviousRes({});
    setDisableFields(false);

    if (selectedRow) {
      GetSleepApneaData();
      GetSleepApneaImages();
      GetSleepApneaImageEvaluationResult();
    }
  }, [evalstatus, selectedRow]);

  useEffect(() => {
    GetQuestions();
  }, []);

  useEffect(() => {
    if (answerData) {
      const yesSum = answerData?.filter(
        (answer) => answer.response === "yes"
      ).length;

      setQuestionCount(yesSum);
      setTotalScore(yesSum);
    }
  }, [answerData]);

  useEffect(() => {
    if (imageEvaluationData) {
      const yesSum1 = answerData?.filter(
        (answer) => answer.response === "yes"
      ).length;
      let count = 0;

      for (const key in imageEvaluationData) {
        if (
          imageEvaluationData.hasOwnProperty(key) &&
          imageEvaluationData[key] === "yes"
        ) {
          count++;
        }
      }
      setYesCount(count);
      const tt = yesSum1 + count;

      setTimeout(() => {
        setTotalScore(tt);
      }, [1000]);
    }
  }, [imageEvaluationData]);

  useEffect(() => {
    if (unFilteredPatientListData?.length > 0) {
      var filterPatient = unFilteredPatientListData?.filter((patient) => {
        return filters.every((filter) => {
          return (
            patient[filter.name] &&
            patient[filter.name]
              .toString()
              .toUpperCase()
              .startsWith(filter.value.toString().toUpperCase())
          );
        });
      });
    }
    setPatientsList(filterPatient);
  }, [filters]);

  useEffect(() => {
    if (evaluationStatus && CustomerAdminDetails) {
      GetPatientList();
      setImageEvaluationData();
      reset1();
      setAssessmentCode();
      setYesCount(0);
    }
  }, [evaluationStatus, CustomerAdminDetails, selectedNotificationPatientType]);

  useEffect(() => {
    if (
      evaluationStatus &&
      CustomerAdminDetails &&
      notificationsList?.length > 0 &&
      !selectedRow
    ) {
      GetPatientList();
      setImageEvaluationData();
      reset1();
      setAssessmentCode();
      setYesCount(0);
    }
  }, [notificationsList, evaluationStatus, CustomerAdminDetails]);

  useEffect(() => {
    if (selectedPatientFromNotification && CustomerAdminDetails) {
      ShowPatientFromNotification();
    }
  }, [
    selectedPatientFromNotification,
    CustomerAdminDetails,
    selectedNotificationPatientType,
  ]);

  useEffect(() => {
    if (selectedPatientFromNotification && patientListData.length > 0) {
      const selectedPatientIs = patientListData.filter(
        (patient) => patient.patient_number == selectedPatientFromNotification
      );
      setSelectedRow(selectedPatientIs[0]);
      setTimeout(() => {
        scrollToBottom.current?.scrollIntoView({ behavior: "smooth" });
      }, 500);
    }
  }, [selectedPatientFromNotification, patientListData]);

  const formatDate = (dateString) => {
    const date = moment(dateString);
    return date.format("D MMMM YYYY");
  };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} centered={true}>
        <ModalBody>
          <img
            className="cursor"
            src={selectedImage}
            style={{ width: "100%" }}
          />
        </ModalBody>
      </Modal>

      <div
        className="shadow border rounded-3 p-3 px-md-3 py-md-1 mb-md-4"
        style={{ backgroundColor: "#f9f9f9" }}
      >
        <div className="table-responsive-xxl pt-3  mb-0 ">
          <table className="table  table-responsive border rounded-3 table-hover">
            <thead className="">
              <tr className="">
                {patientHeader?.map((headers, index) => (
                  <th
                    className="bg-base-color2 border-0 text-white"
                    key={index}
                  >
                    <span className="base-font-600 fs-6 text-uppercase text-center">
                      {headers?.header}
                    </span>
                    {headers?.searchtype === "id" && (
                      <div className="py-3 px-1 border-radius-10 shadow-none w-100 fs-6 title-font-500 mb-1"></div>
                    )}
                    {headers?.dbkey !== "index" && (
                      <span>
                        {headers?.searchtype === "input" ? (
                          <ReactSearchAutocomplete
                            items={patientsList}
                            fuseOptions={{ keys: [headers?.dbkey] }}
                            resultStringKeyName={headers?.dbkey}
                            onSearch={(event) =>
                              handleSearch(headers?.dbkey, event)
                            }
                            onSelect={(event) =>
                              handleOnSelect(
                                headers?.dbkey,
                                event[headers?.dbkey]
                              )
                            }
                            onClear={(event) => handleOnClear(headers?.dbkey)}
                            showIcon={false}
                            placeholder={headers?.header + "..."}
                            className="shadow-none w-100 fs-6 title-font-500 rounded-1 py-0"
                            styling={{
                              borderRadius: "8px",
                              zIndex: 2,
                            }}
                          />
                        ) : headers?.searchtype === "select" ? (
                          <Select
                            options={evalStatus}
                            onChange={handleSelectStatus}
                            className="mb-2 border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input text-black"
                            isClearable={true}
                          />
                        ) : (
                          <>
                            <Select
                              placeholder=""
                              className="mb-1 border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input text-black"
                              isDisabled={true}
                            />
                          </>
                        )}
                      </span>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            {loaders ? (
              <tbody>
                <tr>
                  <td
                    colSpan="8"
                    className="base-font-600 fs-6 text-black text-center"
                  >
                    Loading...
                  </td>
                </tr>
              </tbody>
            ) : patientsList && patientsList.length > 0 ? (
              <tbody className="">
                {patientsList?.map((item, index) => (
                  <tr
                    key={item.id}
                    style={{ cursor: "pointer" }}
                    className={
                      selectedRow && selectedRow.id === item.id
                        ? "selected-row"
                        : "border"
                    }
                    onClick={() => handleRowClick(item)}
                  >
                    {patientHeader?.map((columnName, phindex) => (
                      <td
                        key={phindex}
                        className="base-font-500 fs-6 text-black"
                      >
                        {columnName?.dbkey === "index"
                          ? `${index + 1}`
                          : item[columnName.dbkey] == undefined
                          ? "-----------"
                          : item[columnName.dbkey] == null
                          ? "-----------"
                          : columnName.dbkey == "last_modified_date"
                          ? formatDate(item[columnName.dbkey])
                          : item[columnName.dbkey]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td
                    colSpan="8"
                    className="base-font-600 fs-6 text-black text-center"
                  >
                    Data not found!
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      </div>

      {selectedRow && (
        <div className="">
          <span ref={scrollToTop}></span>

          <div ref={scrollToBottom}></div>
          <div
            className="p-4 bg-base-color rounded-1 border-bottom sticky-top"
            style={{ top: 70 }}
          >
            <div className="d-flex justify-content-center align-items-center gap-4 flex-wrap">
              <h6 className="title-font-700 fs-6 mb-0 text-white">
                Name:{" "}
                <span className="title-font-500">
                  {selectedRow?.first_name} {selectedRow?.last_name}
                </span>
              </h6>
              <h6 className="title-font-700 fs-6 mb-0 text-white">
                Phone No. :{" "}
                <span className="base-font-500">
                  {selectedRow?.contact_number}
                </span>
              </h6>
              <h6 className="title-font-700 fs-6 mb-0 text-white">
                Email:{" "}
                <span className="base-font-500">{selectedRow?.email}</span>
              </h6>
              <h6 className="title-font-700 fs-6 mb-0 text-white">
                Emr No. :{" "}
                <span className="base-font-500">{selectedRow?.number}</span>
              </h6>
            </div>
          </div>

          <div className="px-4 py-4 my-3 bg-base-color rounded-1">
            <Form
              onSubmit={handleSubmit1(onSubmitSleepApnea)}
              autoComplete="off"
            >
              <div
                className="d-flex justify-content-between align-items-center my-2"
                style={{ cursor: "pointer" }}
              >
                <h5 className="title-font-600 text-light fs-5 border-bottom-color-2">
                  Sleep Apnea Screening Evaluation
                </h5>
              </div>
              <div className="row flex-wrap g-3 mx-1">
                <div
                  className="col-12 d-flex justify-content-between mx-auto mx-md-0 col-md-12 col-lg-12"
                  onClick={toggleCollapse}
                >
                  <label
                    htmlFor="type"
                    className="fs-5 title-font-500 mb-0 text-white text-decoration-underline "
                  >
                    Screening Questions:
                  </label>
                  <div>
                    {isCollapsed ? (
                      <ArrowSquareDown size="32" color="#ffffff" />
                    ) : (
                      <ArrowSquareUp size="32" color="#ffffff" />
                    )}
                  </div>
                </div>

                <Collapse isOpened={isCollapsed}>
                  <div className="container">
                    <div className="row flex-wrap g-3 mx-1">
                      {questionsData.map((item) => (
                        <div
                          key={item.id}
                          className="col-11 mx-auto mx-md-0 col-md-6 col-lg-4 d-flex flex-column justify-content-between"
                        >
                          <label
                            htmlFor="type"
                            className="fs-6 title-font-500 mb-0 text-white w-100"
                          >
                            {item.question_list}
                          </label>
                          <input
                            type="text"
                            value={
                              answerData?.find(
                                (answer) => answer.question === item.question
                              )?.response || ""
                            }
                            disabled={true}
                            className="border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input p-2"
                          />
                        </div>
                      ))}

                      <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-4 d-flex flex-column justify-content-between">
                        <label
                          htmlFor="type"
                          className="fs-6 title-font-500 mb-0 text-white w-100"
                        >
                          Questions Score:
                        </label>
                        <input
                          type="text"
                          value={questionCount}
                          disabled={true}
                          className="border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input p-2"
                        />
                      </div>
                    </div>
                  </div>
                </Collapse>

                <div className="col-12 mx-auto border-top"></div>

                <div className="row row-gap-3 justify-content-evenly align-items-center mx-0 m-3  p-3 rounded-3">
                  <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-3  text-center border p-3 rounded-3 shadow images_card bg-white">
                    <h6 className="title-font-600 text-black fs-6  text-black py-b">
                      Throat
                    </h6>
                    <label className="image_overlay">
                      <div className="image_box ">
                        <img
                          className="cursor"
                          src={sleepApneaImages?.image1}
                          onClick={() =>
                            handleImageZoom(sleepApneaImages?.image1)
                          }
                        />
                      </div>
                    </label>
                  </div>
                  <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-3  text-center border p-3 rounded-3 shadow images_card bg-white">
                    <h6 className="title-font-600 text-black fs-6  text-black py-b">
                      Top Palate
                    </h6>
                    <label className="image_overlay">
                      <div className="image_box ">
                        <img
                          className="cursor"
                          src={sleepApneaImages?.image2}
                          onClick={() =>
                            handleImageZoom(sleepApneaImages?.image2)
                          }
                        />
                      </div>
                    </label>
                  </div>
                  <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-3  text-center border p-3 rounded-3 shadow images_card bg-white">
                    <h6 className="title-font-600 text-black fs-6  text-black py-b">
                      Side Bite
                    </h6>
                    <label className="image_overlay">
                      <div className="image_box" onClick={() => setOpen(!open)}>
                        <img
                          className="cursor"
                          src={sleepApneaImages?.image3}
                          onClick={() =>
                            handleImageZoom(sleepApneaImages?.image3)
                          }
                        />
                      </div>
                    </label>
                  </div>
                </div>

                <div className="col-12 mx-auto border-top"></div>

                <div className="col-12 mx-auto mx-md-0 col-md-12 col-lg-12">
                  <label
                    htmlFor="type"
                    className="fs-5 title-font-500 mb-0 text-white text-decoration-underline "
                  >
                    Image Evaluation:
                  </label>
                </div>

                <div className="container">
                  <div className="row flex-wrap g-3">
                  {(evaluationStatus.value == "completed" ||
                evaluationStatus.value == "reported"
                  ? imageEvaluationData
                  : !imageEvaluationData) &&
                  ImageEvaluationQuestions.map((question) => {
                    const namee = question.toLowerCase().split(" ").join("_");

                    return (
                      <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-3 pb-3 pt-1 d-flex flex-column justify-content-between">
                        <label
                          htmlFor="type"
                          className="fs-6 title-font-500 mb-0 text-white"
                        >
                          {`${question}:`}
                        </label>
                        <div>
                        <Controller
                          control={control1}
                          name={namee}
                          rules={{
                            required: "This is a required field *",
                          }}
                          defaultValue={() => {
                            const existingResponse =
                              imageEvaluationData && imageEvaluationData[namee];
                            const valuee = DentalPain.find(
                              (pain) => pain.value == existingResponse
                            );

                            return valuee ? valuee : null;
                          }}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              options={DentalPain}
                              value={value}
                              onChange={(newValue) => {
                                onChange(newValue);
                                handleYesChange(newValue, namee);
                              }}
                              isDisabled={disableFields}
                              className="border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input "
                            />
                          )}
                        />
                        {errors1[namee] && (
                          <ErrorComponents error={errors1[namee]?.message} />
                        )}

                        </div>
                        
                      </div>
                    );
                  })}


                  </div>
                </div>

                
                <div className="col-12 mx-auto border-top"></div>

                <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-3">
                  <label
                    htmlFor="type"
                    className="fs-6 title-font-500 mb-0 text-white"
                  >
                    Assessment Score:
                  </label>
                  <input
                    type="text"
                    value={yesCount}
                    disabled={true}
                    className="border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input p-2"
                  />
                </div>

                {sleepApneaImages?.treatment_recommendation_code &&
                sleepApneaImages?.eval_status == "reported" ? (
                  <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-3">
                    <label
                      htmlFor="type"
                      className="fs-6 title-font-500 mb-0 text-white"
                    >
                      Assessment Code:
                    </label>

                    <input
                      type="text"
                      value={assessmentCode}
                      disabled={evaluationStatus.value === "reported"}
                      onChange={(e) => setAssessmentCode(e.target.value)}
                      className="border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input p-2"
                    />
                  </div>
                ) : (
                  <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-3 pb-3 pt-1">
                    <label
                      htmlFor="type"
                      className="fs-6 title-font-500 mb-0 text-white"
                    >
                      Assessment Code:
                    </label>

                    <Controller
                      control={control1}
                      name="assessment_code"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          options={AssessmentCode}
                          value={
                            value || assessmentCode
                              ? AssessmentCode.find(
                                  (code) => code.value == assessmentCode
                                )
                              : ""
                          }
                          onChange={(newValue) => {
                            onChange(newValue);
                            setAssessmentCode(newValue.value);
                          }}
                          isDisabled={disableFields}
                          className="border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input "
                        />
                      )}
                    />
                  </div>
                )}

                <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-3"></div>

                <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-3">
                  <label
                    htmlFor="type"
                    className="fs-6 title-font-500 mb-0 text-white"
                  >
                    Total Overall Screening Score:
                  </label>
                  <input
                    type="text"
                    value={totalScore}
                    disabled={true}
                    className="border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input p-2"
                  />
                </div>

                <div className="col-11 mx-auto col-md-12 pb-1 pt-1">
                  <label className="fs-6 title-font-500 mb-0 text-white">
                    Evaluation Comment:
                  </label>
                  <div className="mt-1 d-flex gap-3">
                    <textarea
                      {...register1("sleep_apnea_comments")}
                      type="text"
                      onChange={(e) => setSleepApneaComments(e.target.value)}
                      placeholder="Enter Comment..."
                      disabled={disableFields}
                      value={sleepApneaComments}
                      className="p-2 border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input"
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center justify-content-lg-end mt-3">
                  {loader ? (
                    <button
                      type="button"
                      className="border-0 rounded-1 py-2  px-3 bg-base-color2 text-white title-font-500 d-flex gap-2 align-items-center"
                    >
                      <span
                        className="spinner-border spinner-border-sm"
                        disabled
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Submiting . . .
                    </button>
                  ) : (
                    <button
                      className=" border-0 rounded-1 py-2 mb-2 px-3 bg-base-color2 text-white title-font-500"
                      disabled={disableFields}
                    >
                      Submit Sleep Apnea Screening Results
                    </button>
                  )}
                </div>
              </div>
            </Form>
          </div>
        </div>
      )}
    </>
  );
};
