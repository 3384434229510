import React from 'react'
import { Routes, Route } from 'react-router-dom';
import Login from '../pages/Login/Login';
import Registration from '../pages/Registration/Registration';
import EmailVerification from '../pages/Verification/EmailVerification';
import Import from '../pages/Nurse/Import';
import ForgetPassword from '../pages/Login/ForgetPassword';
import Help from '../pages/Login/Help';

const GuestRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      {/* <Route path="/registration" element={<Registration />} /> */}
      <Route path="/verify-email" element={<EmailVerification redirectURL={"/login"} />} />
      <Route path="/forget-password" element={<ForgetPassword />} />
      <Route path="/help" element={<Help />} />
    </Routes>
  )
}

export default GuestRoute