import AWS from 'aws-sdk';

export const Profile_Bucket = 'virtualscreeningprofilepic';
export const Screening_Image_Bucket = 'screening-images';
export const REGION = 'us-east-1';
export const accessKeyId = 'AKIA2F5EPKY5DZDMPRCX';
export const secretAccessKey = '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';

export const awsConfigration = AWS.config.update({
    accessKeyId: accessKeyId,
    secretAccessKey: secretAccessKey
});

const VirtualScreeningProfileBucket = new AWS.S3({
    params: { Bucket: Profile_Bucket },
    region: REGION,
});

export const VirtualScreeningImageBucket = new AWS.S3({
    params: { Bucket: Screening_Image_Bucket },
    region: REGION,
});

export const UploadProfileLink = "https://virtualscreeningprofilepic.s3.amazonaws.com/";
export const ScreeningImageLink = `https://screening-images.s3.amazonaws.com/`;

export const uploadProfilePic = async (data, timestamp) => {
    const params = {
        ACL: "public-read",
        Body: data,
        Bucket: Profile_Bucket,
        Key: `${timestamp}_${data?.name}`,
    };
    await VirtualScreeningProfileBucket.upload(params).promise();
};

export const UploadScreeningImages = async (data, imagename) => {
    const params = {
        ACL: "public-read",
        Body: data,
        Bucket: Screening_Image_Bucket,
        Key: `${imagename}`,
    };
    const res = await VirtualScreeningImageBucket.upload(params).promise();
};