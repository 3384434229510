import React, { useState } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import UserPool from "../../constants/UserPool";
import { NavLink, useNavigate } from "react-router-dom";
import AWS from "aws-sdk";
import { ApiEndPoint } from "../../constants/apiEndPoints/endPoints";
import axios from "axios";
import { useDispatch } from "react-redux";
import {
  CallCalling,
  Eye,
  EyeSlash,
  Lock,
  SmsNotification,
  User,
  UserTick,
} from "iconsax-react";
import { registrationUserName } from "../../redux/Slice/RegistrationSlice";
import { errorAlert, successAlert } from "../../Components/Alerts/Alerts";
import ErrorComponents from "../../Components/ErrorComponents/ErrorComponents";
import PhoneInput from "react-phone-input-2";
import Select from 'react-select';
import InputMask from 'react-input-mask';
import { toast } from "react-hot-toast";

const Registration = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [PasswordToggle, setPasswordToggle] = useState(false);
  const [ConfirmPassword, setConfirmPassword] = useState(false);
  const [load, setLoad] = useState(false);

  const options = [
    { value: 'clinic_community_screening', label: 'Clinic Community Screening' },
    { value: 'clinic_individual_screening', label: 'Clinic Individual Screening' },
    { value: 'clinic_integrated_screening', label: 'Clinic Integrated Screening' },
    { value: 'community_screening', label: 'Community Screening' },
  ];

  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    data.service_type = data.service_type.value;
    var group = "customeradmin";
    if (data.service_type === "community_screening") {
      group = "community_screening"
    }
    setLoad(true);

    data.phoneNumber = `${data.phoneNumber}`;
    const attributesList = [
      new CognitoUserAttribute({
        Name: "email",
        Value: data.email,
      }),
      new CognitoUserAttribute({
        Name: "given_name",
        Value: data.name,
      }),
      new CognitoUserAttribute({
        Name: "name",
        Value: data.username,
      }),
      new CognitoUserAttribute({
        Name: "phone_number",
        Value: `+1${data.phoneNumber.replace(/[\s\-()]/g, '')}`,
      })
    ];

    const CreateCustomerPayload = {
      name: data.name,
      customer_admin_user_id: data.username,
      phone_number: data.phoneNumber,
      email: data.email,
      service_type: data?.service_type
    };


    UserPool.signUp(
      data.username,
      data.password,
      attributesList,
      null,
      async (err, response) => {
        if (err) {
          if (err.message === "Invalid phone number format.") {
            errorAlert(`${err.message} \n eg: +1(111)111-1111`);
            setLoad(false);
          } else {
            errorAlert(err.message);
            setLoad(false);
          }
        }
        if (response) {

          await CreateCustomer(CreateCustomerPayload);
          dispatch(registrationUserName(data.username));
          var cognitoidentityserviceprovider =
            new AWS.CognitoIdentityServiceProvider({
              accessKeyId: "AKIA2F5EPKY5DZDMPRCX",
              secretAccessKey: "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",
              region: "us-east-1",
              AWS_CONFIG_FILE: 1,
            });
          var params = {
            GroupName: group /* required  customeradmin superadmin */,
            UserPoolId: "us-east-1_dHwclMn0X",
            Username: data.username,
          };
          var params2 = {
            UserPoolId: "us-east-1_dHwclMn0X",
            Username: data.username,
          };

          cognitoidentityserviceprovider.adminConfirmSignUp(params2, function (err, data) {
            if (err) toast.error("An error occurred. Please try again.");
          });

          setLoad(false);
          successAlert("Registrations successfully");
          reset({
            name: "",
            service_type: "",
            phoneNumber: "",
            email: "",
            username: "",
            password: "",
            cnfpassword: ""
          });


          cognitoidentityserviceprovider.adminAddUserToGroup(
            params,
            function (err, data) {
              if (err) toast.error("An error occurred. Please try again.");
              else navigate("/");
            }
          );
        }
      }
    )
  };

  const CreateCustomer = async (payload) => {
    try {
      await axios.post(ApiEndPoint.createCustomer, payload).catch((err) => {
        if (err) {
          toast.error("An error occurred. Please try again.")
        }
      })
    } catch (error) {
      if (error) {
        toast.error("An error occurred. Please try again.")
      }
    }
  };

  return (
    <section className="d-flex justify-content-center align-items-center vh-100 ">
      <div className="container py-5 h-100 ">
        <div className="row d-flex justify-content-center align-items-center h-100 mx-2 mx-md-0">
          <div className="col col-xl-10 p-0">
            <div className="card border-0 rounded-4 bg-white shadow-lg p-0 ">
              <div className="d-flex g-0 p-2 justify-content-center align-items-center">
                <div className="col col-lg-5 d-none  d-lg-block ">
                  <img
                    src="https://img.freepik.com/free-photo/dentist-doing-check-up-patient_23-2149206256.jpg?w=360&t=st=1688800550~exp=1688801150~hmac=0863dcb9ff0f7f5264d16df441d9b00ae450f7f148b3a60876eef4ad438ceaf0"
                    alt="login form"
                    className="img-fit rounded-4"
                  />
                </div>
                <div className="col col-lg-7 d-flex align-items-center">
                  <div className="card-body px-2  px-md-4  py-0 ">
                    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                      <div className="d-flex align-items-center py-2">
                        <span className="fs-2 title-font-800  mb-0">
                          Registration{" "}
                          <span className="text-base-color2">Now!</span>
                        </span>
                      </div>
                      <FormGroup className="">
                        <label
                          htmlFor="lname"
                          className="m-0 fs-6  title-font-600"
                        >
                          Name
                        </label>
                        <Controller
                          control={control}
                          name="name"
                          rules={{
                            required: true,
                          }}
                          render={({ field: { onChange, value } }) => (
                            <div className="d-flex align-items-center rounded-3 base-white-light p-1 px-2">
                              <div className="bg-white p-1 rounded-3">
                                <User size="25" className="text-base-color" />
                              </div>
                              <Input
                                type="text"
                                invalid={errors.name}
                                valid={errors.name}
                                className="border-0 shadow-none bg-transparent title-font-500"
                                id="name"
                                placeholder="Name..."
                                autoComplete="off"
                                onChange={onChange}
                                value={value}
                              />
                            </div>
                          )}
                        />
                        {errors.name?.type === "required" && (
                          <ErrorComponents error={"Please enter name."} />
                        )}
                      </FormGroup>

                      <FormGroup className="">
                        <label
                          htmlFor="service_type"
                          className="m-0 fs-6  title-font-600"
                        >
                          Service Type
                        </label>
                        <Controller
                          control={control}
                          name="service_type"
                          autoComplete="nope"
                          rules={{
                            required: true,
                          }}
                          render={({ field: { onChange, value } }) => (
                            <div className="d-flex align-items-center rounded-3 base-white-light p-1 px-2 registration-dd">
                              <div className="bg-white p-1 rounded-3">
                                <SmsNotification
                                  size="25"
                                  className="text-base-color"
                                />
                              </div>
                              <Select options={options} onChange={onChange} value={value} placeholder={"Select Service Type"} className="w-100 bg-transparent base-white-light shadow-none" />
                            </div>
                          )}
                        />
                        {errors.service_type?.type === "required" && (
                          <ErrorComponents
                            error={"Service Type is required"}
                          />
                        )}
                      </FormGroup>


                      <FormGroup className="">
                        <label
                          htmlFor="phoneNumber"
                          className="m-0 fs-6  title-font-600"
                        >
                          {" "}
                          Phone Number
                        </label>
                        <Controller
                          name="phoneNumber"
                          control={control}
                          rules={{
                            required: "Phone number is required",
                          }}
                          render={({ field: { onChange, value } }) => (
                            <div className="d-flex align-items-center rounded-3 base-white-light p-1 px-2">
                              <div className="bg-white p-1 rounded-3">
                                <CallCalling
                                  size="25"
                                  className="text-base-color"
                                />
                              </div>
                              <div
                                className="p-2 border-radius-10 shadow-none  w-100 fs-6 title-font-500 d-flex gap-2 "
                              >
                                <span>+1</span>
                                <InputMask
                                  mask="(999) 999-9999"
                                  value={value}
                                  onChange={(val) => onChange(val)}
                                  type="tel"
                                  placeholder=" (111)111-1111"
                                  className="bg-transparent title-font-500 w-100 base-white-light border-0 shadow-none p-0"
                                />
                              </div>
                            </div>

                          )}
                        />
                        {errors?.phoneNumber && (
                          <ErrorComponents
                            error={errors.phoneNumber?.message}
                          />
                        )}
                      </FormGroup>

                      <FormGroup className="">
                        <label
                          htmlFor="email"
                          className="m-0 fs-6  title-font-600"
                        >
                          Email
                        </label>
                        <Controller
                          control={control}
                          name="email"
                          autoComplete="nope"
                          rules={{
                            required: true,
                            validate: {
                              matchPattern: (v) =>
                                /^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/.test(
                                  v
                                ) || "Email address must be a valid address",
                            },
                          }}
                          render={({ field: { onChange, value } }) => (
                            <div className="d-flex align-items-center rounded-3 base-white-light p-1 px-2">
                              <div className="bg-white p-1 rounded-3">
                                <SmsNotification
                                  size="25"
                                  className="text-base-color"
                                />
                              </div>
                              <Input
                                type="email"
                                invalid={errors.email}
                                valid={errors.email}
                                className="border-0 shadow-none bg-transparent title-font-500"
                                autoComplete="nope"
                                id="email"
                                placeholder="Please enter email."
                                onChange={onChange}
                                value={value}
                              />
                            </div>
                          )}
                        />
                        {errors.email?.type === "required" && (
                          <ErrorComponents
                            error={"Email address is required"}
                          />
                        )}

                        {errors.email?.type === "matchPattern" && (
                          <ErrorComponents
                            error={"Please enter a valid email address"}
                          />
                        )}
                      </FormGroup>

                      <FormGroup className="">
                        <label
                          htmlFor="username"
                          className="m-0 fs-6  title-font-600"
                        >
                          Customer Admin User Name
                        </label>
                        <Controller
                          control={control}
                          name="username"
                          autoComplete="nope"
                          rules={{
                            required: true,
                          }}
                          render={({ field: { onChange, value } }) => (
                            <div className="d-flex align-items-center rounded-3 base-white-light p-1 px-2">
                              <div className="bg-white p-1 rounded-3">
                                <UserTick
                                  size="25"
                                  className="text-base-color"
                                />
                              </div>
                              <Input
                                type="text"
                                invalid={errors.username}
                                valid={errors.username}
                                className="border-0 shadow-none bg-transparent title-font-500"
                                autoComplete="nope"
                                id="username"
                                placeholder="Please enter user name."
                                onChange={onChange}
                                value={value}
                              />
                            </div>
                          )}
                        />
                        {errors.username?.type === "required" && (
                          <ErrorComponents error={"Please enter username. "} />
                        )}
                      </FormGroup>
                      <div className="row">
                        <FormGroup className="col-12 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6">
                          <Label
                            htmlFor="password"
                            className="m-0 fs-6  title-font-600"
                          >
                            Password
                          </Label>
                          <Controller
                            control={control}
                            name="password"
                            autoComplete="new-password"
                            rules={{
                              required: "Password is required.",
                              minLength: {
                                value: 8,
                                message:
                                  "Password must be at most 8 characters long.",
                              },
                            }}
                            render={({ field: { onChange, value } }) => (
                              <div className="d-flex align-items-center justify-content-between rounded-3 base-white-light p-1 px-2">
                                <div className="d-flex align-items-center w-100">
                                  <div className="bg-white p-1 rounded-3">
                                    <Lock
                                      size="25"
                                      className="text-base-color"
                                    />
                                  </div>
                                  <Input
                                    type={PasswordToggle ? "text" : "password"}
                                    invalid={errors.password}
                                    className="border-0 shadow-none bg-transparent w-100 title-font-500"
                                    id="password"
                                    autoComplete="new-password"
                                    placeholder="Please enter password."
                                    onChange={onChange}
                                    value={value}
                                  />
                                </div>
                                <button
                                  type="button"
                                  className="bg-white border-0 shadow-none p-1 rounded-3"
                                  onClick={() =>
                                    setPasswordToggle(!PasswordToggle)
                                  }
                                >
                                  {PasswordToggle ? (
                                    <Eye
                                      size="25"
                                      className="text-base-color"
                                    />
                                  ) : (
                                    <EyeSlash
                                      size="25"
                                      className="text-base-color"
                                    />
                                  )}
                                </button>
                              </div>
                            )}
                          />
                          {errors.password && (
                            <ErrorComponents error={errors.password.message} />
                          )}
                        </FormGroup>
                        <FormGroup className="col-12 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6">
                          <label
                            htmlFor="cnfpassword"
                            className="m-0 fs-6  title-font-600"
                          >
                            Confirm Password
                          </label>
                          <Controller
                            control={control}
                            name="cnfpassword"
                            autoComplete="new-password"
                            rules={{
                              required: true,
                              validate: (value) =>
                                value === watch("password") ||
                                "Passwords do not match",
                            }}
                            render={({ field: { onChange, value } }) => (
                              <div className="d-flex align-items-center justify-content-between rounded-3 base-white-light p-1 px-2">
                                <div className="d-flex align-items-center w-100">
                                  <div className="bg-white p-1 rounded-3">
                                    <Lock
                                      size="25"
                                      className="text-base-color"
                                    />
                                  </div>
                                  <Input
                                    type={ConfirmPassword ? "text" : "password"}
                                    invalid={errors.cnfpassword}
                                    className="border-0 shadow-none bg-transparent w-100 title-font-500"
                                    id="cnfpassword"
                                    placeholder="Please enter password."
                                    autoComplete="off"
                                    onChange={onChange}
                                    value={value}
                                  />
                                </div>
                                <button
                                  type="button"
                                  className="bg-white border-0 shadow-none p-1 rounded-3"
                                  onClick={() =>
                                    setConfirmPassword(!ConfirmPassword)
                                  }
                                >
                                  {ConfirmPassword ? (
                                    <Eye
                                      size="25"
                                      className="text-base-color"
                                    />
                                  ) : (
                                    <EyeSlash
                                      size="25"
                                      className="text-base-color"
                                    />
                                  )}
                                </button>
                              </div>
                            )}
                          />
                          {errors.cnfpassword?.type === "required" && (
                            <ErrorComponents
                              error={"Please enter confirm password"}
                            />
                          )}
                          {errors.cnfpassword?.type === "validate" && (
                            <ErrorComponents
                              error={"Passwords do not match."}
                            />
                          )}
                        </FormGroup>
                      </div>
                      <FormGroup className="pt-2">
                        <FormGroup>
                          {load ? (
                            <button
                              className="fw-bold btn fs-5 bg-base-color border-0 w-100 rounded-3 d-flex gap-2 align-items-center justify-content-center text-white title-font-bold bg-base-color text-uppercase"
                              type="button"
                              disabled
                            >
                              <span
                                className="spinner-border spinner-border-sm" disabled
                                role="status"
                                aria-hidden="true"
                              ></span>
                              Registering . . .
                            </button>
                          ) : (
                            <button className=" fw-bold btn fs-5 bg-base-color border-0 w-100 rounded-3 text-white title-font-bold text-uppercase ">
                              Sign Up
                            </button>
                          )}
                        </FormGroup>
                        <h6 className="text-black title-font-700 fs-6 text-center py-2">
                          Already sign up?{" "}
                          <NavLink
                            to="/login"
                            className="text-base-color2 title-font-700 fs-6 text-decoration-underline"
                          >
                            Sign In
                          </NavLink>
                        </h6>
                      </FormGroup>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Registration;
