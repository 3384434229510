import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Label } from "reactstrap";
import ErrorComponents from "../../Components/ErrorComponents/ErrorComponents";
import { useSelector, useDispatch } from "react-redux";
import UserPool from "../../constants/UserPool";
import { registrationUserName } from "../../redux/Slice/RegistrationSlice";
import AWS from "aws-sdk";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import "moment-timezone";
import HeaderTitle from "../../Components/ProfileHeader/HeaderTitle";
import { ApiEndPoint } from "../../constants/apiEndPoints/endPoints";
import { errorAlert, successAlert } from "../../Components/Alerts/Alerts";
import { Eye, EyeSlash, Lock, SmsNotification } from "iconsax-react";
import { toast } from "react-hot-toast";

const CreateNurse = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [Loader, setLoader] = useState(false);
  const [PasswordToggle, setPasswordToggle] = useState(false);

  const BranchAdminDetails = useSelector(
    (state) => state.login.loggedUserDetails
  );

  const Submit = (data) => {
    setLoader(true);

    const attributesList = [
      new CognitoUserAttribute({
        Name: "email",
        Value: data.email,
      }),
      new CognitoUserAttribute({
        Name: "given_name",
        Value: data.first_name,
      }),
      new CognitoUserAttribute({
        Name: "name",
        Value: data.username,
      }),
      new CognitoUserAttribute({
        Name: "middle_name",
        Value: data.last_name,
      }),
    ];

    const CreateNursePayload = {
      fname: data.first_name,
      lname: data.last_name,
      address: undefined,
      email: data.email,
      username: data.username,
      branch_id: BranchAdminDetails.id,
    };

    UserPool.signUp(
      data.username,
      data.password,
      attributesList,
      null,
      async (err, response) => {
        if (err) {
          setLoader(false);
          errorAlert(err.message);
        }
        if (response) {
          successAlert(response?.data?.message);
          await CreateNurse(CreateNursePayload);
          dispatch(registrationUserName(data.username));
          var cognitoidentityserviceprovider =
            new AWS.CognitoIdentityServiceProvider({
              accessKeyId: "AKIA2F5EPKY5DZDMPRCX",
              secretAccessKey: "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",
              region: "us-east-1",
              AWS_CONFIG_FILE: 1,
            });
          var params = {
            GroupName: "nurse" /* required */,
            UserPoolId: "us-east-1_dHwclMn0X" /* required */,
            Username: data.username /* required */,
          };
          var params2 = {
            UserPoolId: "us-east-1_dHwclMn0X",
            Username: data.username,
          };

          cognitoidentityserviceprovider.adminConfirmSignUp(params2, function (err, data) {
            if (err) toast.error("An error occurred. Please try again.");
          });
          setLoader(false);
          cognitoidentityserviceprovider.adminAddUserToGroup(
            params,
            function (err, data) {
              if (err) toast.error("An error occurred. Please try again.");
              else navigate("/");
            }
          );
        }
      }
    );
  };

  const CreateNurse = async (payload) => {
    setLoader(true);
    try {
      await axios.post(ApiEndPoint.createNurse, payload).then((response) => {
        successAlert(response?.data?.message);
        setLoader(false);
      }).catch((err) => {
        if (err) {
          toast.error("An error occurred. Please try again.")
        }
      })
    } catch (error) {
      errorAlert(error?.message);
      setLoader(false);
    }
  };

  return (
    <section className="px-1 px-md-2 px-lg-5 pt-2 ">
      {/* <HeaderTitle title="Nurse" create={true} /> */}
      <div className="px-2 py-3 p-md-4  shadow-lg mt-1 border-radius-3">
        <Form onSubmit={handleSubmit(Submit)} autoComplete="off">
          <div className="col-11 mx-auto mx-md-0 pb-3 pt-1 text-center text-md-start">
            <label className="title-font-600 mb-0 fs-5  text-uppercase border-bottom-color2-2">
              Create Nurse
            </label>
          </div>
          <div className="row">
            <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
              <Label className="fs-6 title-font-600 mb-0 text-black">
                First Name
              </Label>

              <div className="mt-1  d-flex gap-3">
                <input
                  className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                  type="text"
                  placeholder="First Name..."
                  {...register("first_name", { required: true })}
                />
              </div>
              {errors.first_name && (
                <ErrorComponents error={"First Name is required *"} />
              )}
            </div>
            <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
              <Label className="fs-6 title-font-600 mb-0 text-black">
                Last Name
              </Label>

              <div className="mt-1  d-flex gap-3">
                <input
                  className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                  type="text"
                  placeholder="Last Name..."
                  {...register("last_name", { required: true })}
                />
              </div>
              {errors.last_name && (
                <ErrorComponents error={"Last Name is required *"} />
              )}
            </div>
            <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
              <Label className="fs-6 title-font-600 mb-0 text-black">
                Email
              </Label>

              <div className="mt-1  d-flex gap-3">
                <input
                  className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                  type="email"
                  autoComplete="nope"
                  placeholder="Email..."
                  {...register("email", { required: true })}
                />
              </div>
              {errors.email && (
                <ErrorComponents error={"Email is required *"} />
              )}
            </div>
            <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
              <Label className="fs-6 title-font-600 mb-0 text-black">
                User Name *
              </Label>
              <div className="mt-1  d-flex gap-3">
                <input
                  className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                  type="text"
                  placeholder="UserName... "
                  {...register("username", {
                    required: true,
                  })}
                />
              </div>
              {errors.username && (
                <ErrorComponents error={"Please enter user name"} />
              )}
            </div>

            <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
              <Label className="fs-6 title-font-600 mb-0 text-black">
                Password
              </Label>

              <div className="mt-1 d-flex gap-3 p-1 px-2 border-radius-10 shadow-none w-100 modal-input">
                <input
                  className=" w-100 border-0 fs-6 title-font-500"
                  type={PasswordToggle ? "text" : "password"}
                  autoComplete="new-password"
                  placeholder="Password..."
                  {...register("password", { required: true })}
                />

                <button
                  type="button"
                  className="bg-white border-0 shadow-none p-1 rounded-3"
                  onClick={() => setPasswordToggle(!PasswordToggle)}
                >
                  {PasswordToggle ? (
                    <Eye size="25" className="text-base-color" />
                  ) : (
                    <EyeSlash size="25" className="text-base-color" />
                  )}
                </button>
              </div>
              {errors.password && (
                <ErrorComponents error={"Password is required *"} />
              )}
            </div>
            <div className="d-flex align-items-center justify-content-center justify-content-md-end  gap-3 pt-3">
              <div className="d-flex align-items-center gap-3">
                {Loader ? (
                  <button
                    type="button"
                    className="submit-btn d-flex gap-2 align-items-center"
                  >
                    <span
                      className="spinner-border spinner-border-sm" disabled
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Creating . . .
                  </button>
                ) : (
                  <button type="submit" className="submit-btn">
                    Submit
                  </button>
                )}
                <button
                  type="button"
                  className="close-btn"
                  onClick={() => navigate("/")}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </section>
  );
};

export default CreateNurse;
