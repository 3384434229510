import React from 'react'
import { FormFeedback, FormGroup, Input } from 'reactstrap'
import { useForm, Controller } from "react-hook-form"
import { CognitoUser } from "amazon-cognito-identity-js";
import UserPool from '../../constants/UserPool';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { SmsNotification } from 'iconsax-react';
import { errorAlert, successAlert } from '../../Components/Alerts/Alerts';
import Navbar from '../../Components/Navbar/Navbar';
import AWS from 'aws-sdk';
import { toast } from 'react-hot-toast';

const EmailVerification = ({ redirectURL }) => {
    const navigate = useNavigate();
    const [load, setLoad] = React.useState(false);
    const userIs = useSelector((state) => state.registration.username);
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();
    const routes = [{
        route: [
            {
                url: "/help",
                name: "Help"
            }
        ]
    }];
    const onSubmit = (data) => {
        setLoad(true);
        const userdata = {
            Username: userIs,
            Pool: UserPool,
        };

        const cognitoUser = new CognitoUser(userdata);
        cognitoUser.confirmRegistration(data.verificationcode, true, (err, data) => {
            if (err) {
                errorAlert(err.message);
                setLoad(false);
            };
            if (data) {
                successAlert("Email verified Successfully!");
                navigate(redirectURL);
                setLoad(false);
            };
        })
    };

    const resendCode = async () => {

        var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({
            accessKeyId: 'AKIA2F5EPKY5DZDMPRCX',
            secretAccessKey: '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',
            'region': 'us-east-1',
            'AWS_CONFIG_FILE': 1
        });

        var params = {
            ClientId: '5gjnnoqq69bnq6ojvdpv7f9cac',
            Username: userIs,
        };

        cognitoidentityserviceprovider.resendConfirmationCode(params, function (err, data) {
            if (err) {
                toast.error("An error occurred. Please try again.")
            } else {
                toast.success('Verification code resent successfully');
            }
        });
    };

    return (
        <>
            <Navbar routes={routes} />
            <section className="d-flex justify-content-center align-items-center py-6" >
                <div className="container py-5 h-100 ">
                    <div className="row d-flex justify-content-center align-items-center h-100 ">
                        <div className="col col-xl-10 p-0 ">
                            <div className="card border-0 rounded-4 bg-white shadow-lg p-2 bg-white">
                                <div className="d-flex g-0 p-2 justify-content-center align-items-center">
                                    <div className="col col-lg-5 d-none  d-lg-block  ">
                                        <img src="https://images.pexels.com/photos/6912829/pexels-photo-6912829.jpeg?auto=compress&cs=tinysrgb&w=600"
                                            alt="login form" className="img-fluid rounded-4" />
                                    </div>
                                    <div className="col col-lg-7 d-flex align-items-center">
                                        <div className="card-body px-2  px-md-4  py-0">
                                            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                                                <div className="d-flex align-items-center">
                                                    <span className="fs-2 title-font-800  mb-0">Email  <span className='text-base-color2'>Verification!</span></span>
                                                </div>
                                                <h6 className="base-font-500 fs-6 pb-2 text-base-gray" >Please verify your account</h6>
                                                <FormGroup>
                                                    <label htmlFor="verificationcode" className='m-0 fs-6  title-font-600'>
                                                        Verification Code
                                                    </label>
                                                    <Controller
                                                        control={control}
                                                        autoComplete='nope'
                                                        rules={{
                                                            required: true,
                                                        }}
                                                        name="verificationcode"
                                                        render={({ field: { onChange, value } }) => (
                                                            <div className='d-flex align-items-center rounded-3 base-white-light p-1 px-2'>
                                                                <div className="bg-white p-1 rounded-3">
                                                                    <SmsNotification size="25" className='text-base-color' />
                                                                </div>
                                                                <Input type='text' invalid={errors.verificationcode} className='border-0 shadow-none bg-transparent title-font-500' id='verificationcode' placeholder='Please enter email verification code.' autoComplete='nope' onChange={onChange} value={value} />
                                                            </div>

                                                        )}
                                                    />
                                                    {errors.cnfpassword?.type === "required" && <FormFeedback>
                                                        Please enter email verification code
                                                    </FormFeedback>}
                                                </FormGroup>
                                                <FormGroup>Lost your code? <span className='text-base-color' role='button' onClick={() => resendCode()}>Resend Code</span></FormGroup>
                                                <FormGroup>
                                                    {
                                                        load ? <button
                                                            className="fw-bold btn fs-5 bg-base-color border-0 w-100 rounded-3 d-flex gap-2 align-items-center justify-content-center text-white title-font-bold bg-base-color text-uppercase"
                                                            type="button"
                                                            disabled
                                                        >
                                                            <span
                                                                className="spinner-border spinner-border-sm" disabled
                                                                role="status"
                                                                aria-hidden="true"
                                                            ></span>
                                                            Verifing . . .
                                                        </button> : <button className='btn bg-base-color border-0 w-100 rounded-3 text-white'>
                                                            Verify
                                                        </button>
                                                    }
                                                </FormGroup>
                                            </form>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default EmailVerification;