import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import CreateBranch from '../pages/CustomerAdmin/CreateBranch';
import Navbar from '../Components/Navbar/Navbar';
import { Profile } from 'iconsax-react';
import Dashboard from '../pages/CustomerAdmin/Dashboard';
import EmailVerification from '../pages/Verification/EmailVerification';
import ScrollToTop from '../Helper/ScrollToTop';

const CustomerAdminRoute = () => {

    const routes = [{
        route: [
            {
                url: "/",
                name: "Dashboard"
            },
            {
                url: "/CreateBranch",
                name: "Create Branch"
            },
        ]
    },
    {
        button: [
            {
                url: "/",
                name: "logout",
                icon: <Profile size="20" />
            }
        ]
    }]
    return (
        <>
            <Navbar routes={routes} />
            <ScrollToTop>
                <div className="position-relative  w-100 vh-100 container-xxl" style={{ top: "92px" }}>
                    <Routes>
                        <Route path="/" element={<Dashboard />} />
                        <Route path="/CreateBranch" element={<CreateBranch />} />
                        <Route path="/verify-email" element={<EmailVerification redirectURL={"/"} />} />
                    </Routes>
                </div>
            </ScrollToTop>
        </>

    )
}

export default CustomerAdminRoute